<template>
  <div class="professionalinvestigation">
    <div class="minw">
      <div class="screen" ref="screenh">
        <div class="condition">
          <div class="searchTitle">专业层次</div>
          <div style="flex: 47">
            <span @click="getsubjectCategories('本科')" :class="{ active: professionalLevel == '本科' }">本科</span>
            <span @click="getsubjectCategories('专科（高职）')"
              :class="{ active: professionalLevel == '专科（高职）' }">专科（高职）</span>
          </div>
        </div>
        <div class="condition" style="min-height:40px">
          <div class="searchTitle">专业门类</div>
          <div style="flex: 47; position: relative">
            <p>
              <span @click="active(itemitem)" v-for="(itemitem, indexitem) in screenarr" :key="indexitem" :class="{
              active: itemitem.subjectcategoriesNumber == subjectcategoriesNumber
            }">{{ itemitem.subjectcategoriesName }}</span>
            </p>
          </div>
        </div>
      </div>
      <div class="wrap">
        <div class="wrap_left">
          <div class="condition" style="align-items: center;display: flex;    width: 80%;">
            <div class="searchTitle" style="flex:3">
              共匹配到 <span style="color: #12B098;">{{ totalNum}}</span> 条数据
            </div>
            <div class="searchInput">
              <el-input placeholder="请输入专业名称" v-model.trim="professionalName" @keyup.enter.native="sousuo"
                clearable></el-input>
              <div class="divicon" @click="sousuo()">
                <i class="el-icon-search" style="color: white;"></i>
              </div>
            </div>
          </div>
          <div class="bottom" v-if="professionList.length == 0" v-loading="loading">
            <img src="../../assets/imgs/nodata.png" style="display:block;margin:0 auto" alt />
          </div>
          <div class="bottom" ref="DOM" v-loading="loading" v-else>
            <div class="professionCss" v-for="(item, index) in professionList" :key="index">
              <div>
                <h1>{{ item.subjectcategoriesName }}
                  <span style="font-size: 14px;color: #686868;margin-left: 30px;font-weight: 100;">{{
                    item.majorPlusVos.length }}个专业类</span>
                </h1>
              </div>
              <div :style="{
              borderBottom:
                indexx != item.majorPlusVos.length - 1 ? '2px solid #f4f4f4' : '',
            }" style="margin-bottom: 10px;" v-for="(itemm, indexx) in item.majorPlusVos" :key="indexx">
                <div style="display: flex;">


                  <div class="secondMajor">{{ itemm.majorplusName }}</div>
                  <div style="flex: 6;">


                    <div v-for="(itemmm, indexxx) in itemm.professionalMajorVos" :title="itemmm.professionalName"
                      :key="indexxx" class="professionItem inlineCss" @click="goprofessionDetail(itemmm)">
                      {{ itemmm.professionalName }}
                      <!-- <span >{{ itemmm.professionalName }}</span> -->
                      <!-- <p style="display:inline-block;">
                    专业代码:&nbsp;{{
                      itemmm.professionalCode
                    }}&nbsp;&nbsp;|&nbsp;&nbsp;修业年限:&nbsp;{{
  itemmm.professionalSystem
}}
                    &nbsp;&nbsp;|&nbsp;&nbsp;授予学位:&nbsp;{{
                      itemmm.professionalDegree ? itemmm.professionalDegree : '---'
                    }}
                  </p>
                  <p style="padding-left:230px;margin-top:10px">
                    &nbsp;&nbsp;性别比例:&nbsp;{{
                      itemmm.peopleRatio ? itemmm.peopleRatio : '---'
                    }}
                  </p> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
        <!-- <div class="wrap_right">
          <img src="../../assets/imgs/schoolright.png" alt="">

        </div> -->
      </div>


    </div>
    <!-- 回到顶部 -->
    <div style="position:fixed;right:10%;bottom:10%;z-index:9999" v-if="scrollShow" @click="goTop">
      <el-tooltip class="item" effect="dark" content="回到顶部" placement="left">
        <i class="el-icon-caret-top" style="font-size:25px;cursor:pointer"></i>
      </el-tooltip>
    </div>
  </div>
</template>

<script>
import { subjectCategories, queryByProfessionalMajor, queryLevelAll } from '@/api/professionalinvestigation.js'
export default {
  name: 'professionalinvestigation',
  components: {},
  props: {},
  data() {
    return {
      scrollShow: false,
      loading: true,
      professionalLevel: '本科',
      menleiflag: false,
      screenarr: [],
      subjectcategoriesNumber: '',
      professionalName: '',
      professionList: [],
      totalNum:0
    }
  },
  computed: {},
  watch: {

  },
  created() {
    // 获取专业门类数组
    this.getsubjectCategories('本科')
    // 获取专业数组
    // this.getByProfessionalMajor()
  },
  mounted() {
    window.addEventListener('load', () => { this.$router.replace({ query: {} }) })
    window.addEventListener('scroll', this.handleScroll) // 监听页面滚动
  },
  methods: {
    handleScroll() {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      if (scrollTop >= 1750) {
        this.scrollShow = true
      } else {
        this.scrollShow = false
      }
    },
    goTop() {
      // document.documentElement.scrollTop = 0
      document.body.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'start'
      })
    },
    // 获取专业门类
    getsubjectCategories(title) {
      const self = this
      self.loading = true
      this.professionalLevel = title
      const obj = {
        professionalLevel: title
      }
      queryLevelAll(obj).then(res => {
        self.screenarr = res.data
        self.screenarr.unshift({
          subjectcategoriesName: '全部',
          subjectcategoriesNumber: ''
        })
        self.subjectcategoriesNumber = self.screenarr[0].subjectcategoriesNumber
        self.getByProfessionalMajor()
      })
    },
    // 获取专业
    getByProfessionalMajor() {
      const self = this
      self.loading = true
      const params = {
        professionalName: this.professionalName,
        professionalLevel: this.professionalLevel,
        subjectcategoriesNumber: this.subjectcategoriesNumber
      }
      queryByProfessionalMajor(params).then(res => {
        if (res.code == 200) {
          self.professionList = Object.freeze(res.data)
          self.totalNum = 0;
          self.professionList.forEach(item=>{
            item.majorPlusVos.forEach(itemm=>{
              self.totalNum += itemm.professionalMajorVos.length
            })
          })
          self.loading = false
        }
      })
    },
    active(item) {
      this.subjectcategoriesNumber = item.subjectcategoriesNumber
      this.getByProfessionalMajor()
    },
    reset() {
      this.professionalName = ''
      this.subjectcategoriesNumber = ''
      this.getByProfessionalMajor()
      // this.getsubjectCategories(this.professionalLevel)
    },
    sousuo() {
      this.subjectcategoriesNumber = ''
      this.getByProfessionalMajor()
    },
    // 专业详情页面
    goprofessionDetail(item) {
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      this.$router.push({
        path: '/goprofessionDetail',
        query: { professionalCode: item.professionalCode, scrollTop: scrollTop }
      })
    }
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  beforeRouteLeave(to, from, next) {
    if (to.path === '/professionalinvestigation') {
      to.meta.keepAlive = false
      next()
    } else {
      next()
    }
  }

}
</script>

<style scoped lang="scss">
.professionalinvestigation {
  box-sizing: border-box;
  width: 100%;
  // background-color: #fcfbf9;
  padding: 0px 0 80px 0;

  .searchInput {
    // width: 627px;
    flex: 7;
  }

  .screen {
    box-sizing: border-box;
    // padding: 35px 30px;
    width: 100%;
    // border: 1px solid #f4f4f4;
    // border-radius: 10px;
    background: white;
    user-select: none;

    .condition {
      display: flex;
      flex-direction: row;
      margin-bottom: 8px;

      .searchTitle {
        font-size: 16px;
        color: #000000;
        margin-right: 15px;
        flex: 3.5;
        line-height: 25px;
      }



      .active {
        background: #12B098;
        border-radius: 8px;
        color: white;
      }

      .active:hover {
        color: white;
      }

      span {
        cursor: pointer;
        display: inline-block;
        margin-right: 9px;
        margin-bottom: 10px;
        font-size: 16px;
        font-weight: 400;
        color: #5F5F5F;
        box-sizing: border-box;
        padding: 3px 8px;
          height: 27px;
      line-height: 21px;
      }

      span:hover {
        color: #12B098;
      }
    }


  }

  .wrap {
    display: flex;

    .wrap_left {
      flex: 1;
      margin-right: 74px;
    }

    .wrap_right {
      width: 300px;
    }
  }

  .bottom {
    box-sizing: border-box;
    min-height: 800px;
    width: 100%;
    // margin-top: 20px;
    // padding: 35px 35px 20px 35px;
    // border: 1px solid #f4f4f4;
    // border-radius: 10px;
    background: white;

    .professionCss {
      border-bottom: 2px solid #f4f4f4;
      padding-bottom: 0px;
      margin-bottom: 12px;

      h1 {
        display: inline-block;
        font-size: 16px;
        font-weight: bold;
        color: #30C9B2;
        margin-bottom: 10px;
      }

      .secondMajor {
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #3E3E3E;

        flex: 1;
      }

      .professionItem {
        font-size: 16px;
        cursor: pointer;
        display: inline-block;
        width: 20%;
        color: #686868;
        margin-bottom: 13px;

        span:nth-of-type(1) {
          display: inline-block;
        }

        span:nth-of-type(1):hover {
          color: #30C9B2;
        }
      }

      .professionItem:hover {
        color: #30C9B2;
      }
    }
  }
}
</style>
